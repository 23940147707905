.btn-asc {
    top: 50%;
    margin-top: -14px;
    margin-left: 8px;
    position: absolute;
}

.btn-desc {
    top: 50%;
    margin-top: 3px;
    margin-left: 8px;
    position: absolute;
}

.btn-asc-30 {
    top: 30%;
    margin-top: -14px;
    margin-left: 8px;
    position: absolute;
}

.btn-desc-30 {
    top: 30%;
    margin-top: 3px;
    margin-left: 8px;
    position: absolute;
}

.btn-asc-40 {
    top: 40%;
    margin-top: -14px;
    position: absolute;
}

.btn-desc-40 {
    top: 40%;
    margin-top: 3px;
    position: absolute;
}

.multiline{ 
    overflow:hidden; 
    width:150px;  
    /*max-height:60px; */
    display:-webkit-box; 
    -webkit-line-clamp:3; 
    -webkit-box-orient:vertical; 
    /*font-size:14px; 
    line-height:20px; 
    text-overflow:ellipsis; 
    text-align:left;*/
}

.vertical-align-center {
    align-items:center;
}

.horizon-align-center {
    justify-content: center;
}

.horizon-align-right {
    justify-content: right;
}

.display-flex {
    display: flex;
}
/*
.tooltip {
    position: relative;
  z-index: 10000 !important;
}
*/

.page-nation-align {
    justify-content: center;
}

.pc-tablet-menu-block {
    display: none!important;
}

.reg-page-vod-height {
    height: 363px;
    margin-bottom: 15px;
}

.reg-adv {
    width: 100%;
    height: 237px;
    margin-bottom: 5px;
}

.a-href-underline {
    text-decoration: underline!important;
}

.select-option-default {
    color:#495057!important;
}

.select-option-null {
    color:#f46a6a!important;
}

@media (max-width: 820px) {
    .reg-page-vod-height {
        height: 259px;
    }

    .reg-adv {
        height: 100%;
    }
}

@media (min-width: 576px) {
    .page-nation-align {
        justify-content: space-between!important;
    }

    .pc-tablet-menu-block {
        display: flex!important;
    }
}

.mobile-menu-block {
    display: none!important;
}

@media (max-width: 575px) {
    .mobile-menu-block {
        display: flex!important;
    }

    .reg-page-vod-height {
        height: 222px;
    }
}

@media (max-width: 375px) {
    .reg-page-vod-height {
        height: 200px;
    }
}

.domain-correct-tooltip {
    position: relative;
    width: 200px; 
    z-index: 10000 !important;
}

.btn-outline-disable {
    color: #1B4677 !important;
    border-color: #1B4677 !important;
}

.btn-wcdark {
    width: 115.51px;
}

.billing-switch {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#fff'/></svg>") !important;
}

.form-check-right {
    padding-left: 0px !important;
}

.form-check-input-right {
    float: right !important;
    margin-right: 0em;
}

.text-align-center {
    text-align: center;
}

td {
    background-color: transparent !important;
}

th {
    background-color: transparent !important;
}