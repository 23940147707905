@keyframes line--animation {
    0% {fill-rule:evenodd;clip-rule:evenodd;stroke-dasharray: 1000;
        stroke-dashoffset:1000; stroke:#fff; fill:#fff; stroke-width:2px; fill-opacity: 0;}
    100% {fill-rule:evenodd;clip-rule:evenodd; fill-opacity: 1; stroke-dashoffset:0; stroke-width:0;  fill:#fff;}
}
#svg--email path{animation: line--animation 2s; fill:#fff; animation-delay: 1s; animation-timing-function:cubic-bezier(0.55, 0.055, 0.675, 0.19); animation-fill-mode: both; fill-rule:evenodd;clip-rule:evenodd;stroke-dasharray: 1000;
    stroke-dashoffset:1000;}

#activeEmailModal .modal-dialog{border : 1px solid #d9d9d9}
#activeEmailModal .modal-body{padding:0;}
#activeEmailModal .welcome--head{background:rgb(92,141,198); text-align: center; padding:35px 0;}
#activeEmailModal .welcome--title{font-size:3rem; font-weight:normal; color:rgb(92,141,198)}
#activeEmailModal .welcome--text{font-size:1rem;}
#activeEmailModal .welcome--text span{font-size:1rem; margin-top:10px; display:block;}
#activeEmailModal .welcome--text a{font-weight:bold; color:rgb(92,141,198); text-decoration: underline; transition:all .3s;}
#activeEmailModal .welcome--text a:hover{color:#3fc969}
#activeEmailModal .welcome--body{padding-top:30px; text-align: center;}
#activeEmailModal .modal-footer{text-align: center; padding-bottom:30px; display: block; justify-content: center;}

.welcome--head{background:rgb(92,141,198); text-align: center; padding:40px 0;}
.welcome--title{font-size:3rem; font-weight:normal; color:rgb(92,141,198)}
.welcome--text{font-size:1rem;}
.welcome--text span{font-size:1rem; margin-top:10px; display:block;}
.welcome--text a{font-weight:bold; color:rgb(92,141,198); text-decoration: underline; transition:all .3s;}
.welcome--text a:hover{color:#3fc969}
.welcome--body{padding-top:30px; text-align: center;}
.modal-footer{text-align: center; padding-bottom:30px; display: block; justify-content: center;}

/* welcomeModal */
#welcomeModal .modal-dialog{border : 1px solid #d9d9d9}
#welcomeModal .modal-body{padding:0;}
#welcomeModal .welcome--head{background:rgb(92,141,198); text-align: center; padding:35px 0;}
#welcomeModal .welcome--title{font-size:3rem; font-weight:normal; color:rgb(92,141,198)}
#welcomeModal .welcome--text{font-size:1rem;}
#welcomeModal .welcome--text span{font-size:1rem; margin-top:10px; display:block;}
#welcomeModal .welcome--text a{font-weight:bold; color:rgb(92,141,198); text-decoration: underline; transition:all .3s;}
#welcomeModal .welcome--text a:hover{color:#3fc969}
#welcomeModal .welcome--body{padding-top:30px; text-align: center;}
#welcomeModal .modal-footer{text-align: center; padding-bottom:30px;}


/* use cookie 관련 css */
.cp-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: black;
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    opacity: 0.5;
    left: 0;
    top: 0;
    z-index: 999998;
    display: none;
}

#cp-dialog {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 999999;
    color: white;
    display: none;
}

.cp-layer {
    width: 100%;
    max-width: 1000px;
    min-width: 320px;
    margin: 0 auto !important;
    min-height: 50px;
    background-color: white;
}

.cp-layer div.cp-inner {
    margin: 0 auto;
    position: relative;
    padding: 13px 20px 23px 20px;
    color: rgb(102, 102, 102);
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
}

.cp-layer div.cp-inner h5 {
    margin: 0;
    font-size: 22px;
    color: #666666;
    font-weight: 400;
    line-height: 27px;
}

.cp-layer div.description {
    max-width: 700px;
    padding: 15px 0 0 0;
}

.cp-layer div.links {
    max-width: 500px;
    padding: 13px 0 0 0;
    margin-right: 75px;
}

.cp-layer.opt-in div.description {
    max-width: none;
    padding: 15px 0 0 0;
}

.cp-layer.opt-in div.links {
    display: none;
}

.cp-layer div.links a {
    margin: 0 15px 0 0;
    line-height: 14px;
    color: #333;
    background-size: 4px 14px;
    background-repeat: no-repeat;
    text-decoration: underline;
}

.cp-layer div.links a:hover {
    color: #00ADEF !important;
    background-size: 4px 14px;
    background-repeat: no-repeat;
}

.cp-layer p.close {
    position: absolute !important;
    bottom: 10px !important;
    right: 20px !important;
    background: #00adef;
    border-radius: 1px;    
    text-decoration: underline;
}

.close-button-text {
    color: black;
    font-size: 13px;
    cursor: pointer;
}

/*
.cp-layer p.close a {
    color: #FFFFFF;
    float: left;
    font-size: 14px;
    padding: 0 10px 0 10px;
    line-height: 24px;    
    background-image: url(../images/close.svg);
    background-size: 19px 17px;
    width: 17px;
    height: 17px;
}
*/

#cp-dialog .cp-layer .cp-inner .links a{color:#333}
#cp-dialog .cp-layer .cp-inner .close{background-color:inherit;bottom:inherit !important;border:0;top:-5px !important;right:30px !important;}
#cp-dialog .cp-layer .cp-inner .close a:after{ content:''; position:absolute; top:0; right:0; width:17px; height:17px; }
#cp-dialog .cp-layer .cp-inner .close a:before{font-size:17px;display:block;color:#333;speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:"";opacity:1}
#cp-dialog .cp-layer .cp-inner .close a:active:before{color:#00adef}
